// import type { Tables } from '@/types/database.types.ts'
import union from 'lodash.union';
import { useI18n, useState, useSupabaseClient, useToast } from '#imports';

export const useMaps = () => {
  const { t } = useI18n();
  const { showError } = useToast();
  const isLoading = useState<boolean>('isLoading', () => false);
  const noMoreMaps = useState<boolean>('noMoreMaps', () => false);
  const supabase = useSupabaseClient();

  const fetchMaps = async (filters, search?: string, limit?: number) => {
    let query = supabase
      .from('maps')
      .select(`
        *,
        profiles:user_id (
          id,
          mappr_handle,
          profile_image_url,
          display_name
        ),
        spots!inner(
          city:cities_id!inner(
            country,
            name
          ),
          category:category_id!inner(
            name,
            icon
          )
        )
      `)
      .eq('is_published', true)
      .order('created_at', { ascending: false });

    if (filters.country?.length) {
      query = query.or(
        filters.country
          .map((country: string) => `country.ilike.${country}`)
          .join(','),
        { referencedTable: 'spots.city' },
      );
    }

    if (filters.city?.length) {
      query = query.or(
        filters.city.map((city: string) => `name.ilike.${city}`).join(','),
        { referencedTable: 'spots.city' },
      );
    }

    if (filters.category?.length) {
      query = query.or(
        filters.category
          .map((category: string) => `name.ilike.${category}`)
          .join(','),
        { referencedTable: 'spots.category' },
      );
    }

    if (search)
      query = query.textSearch('fts', `${search.replace(/ /g, '+')}:*`);

    if (limit) query = query.limit(limit);

    const { data, error } = await query;
    if (error) showError(t('messages.maps.error-loading'));
    return data;
  };

  const fetchDestinations = async (limit = null) => {
    const { data: maps, error: mapsError } = await useSupabaseClient()
      .from('combined_search')
      .select('city_ids')
      .eq('type', 'map');

    if (mapsError) showError(t('messages.maps.error-loading'));

    const flattenCities = maps.reduce((acc, item) => {
      return acc.concat(item.city_ids.filter((id) => id !== null));
    }, []);

    let query = supabase
      .from('cities')
      .select('*')
      .in('id', union(flattenCities))
      .neq('cover_image_url', 'null')
      .order('updated_at', { ascending: false });

    if (limit) {
      query = query.limit(limit);
    }

    const { data, error } = await query;
    if (error) showError(t('messages.maps.error-loading'));

    return data;
  };

  const fetchCategories = async () => {
    const { data: maps, error: mapsError } = await useSupabaseClient()
      .from('combined_search')
      .select('category_ids')
      .eq('type', 'map');

    if (mapsError) showError(t('messages.maps.error-loading'));

    const flattenCategories = maps.reduce((acc, item) => {
      return acc.concat(item.category_ids.filter((id) => id !== null));
    }, []);

    const { data, error } = await supabase
      .from('categories')
      .select('*')
      .in('id', union(flattenCategories))
      .order('created_at', { ascending: false });

    if (error) showError(t('messages.maps.error-loading'));
    return data;
  };

  const fetchMapBySlug = async (slug: string) => {
    const { data, error } = await useSupabaseClient()
      .rpc('get_map_by_slug', { search_slug_param: slug })
      .select(`
        *,
        spots (
          *,
          map_slug:map_id!inner(
            current_slug
          ),
          categories:category_id (
            id,
            name,
            icon
          ),
          cities:cities_id (
            id,
            name,
            country
          )
        ),
        profiles:user_id (
          id,
          mappr_handle,
          profile_image_url,
          display_name,
          stripe_account_id
        )
      `)
      .single();
    if (error) showError(t('messages.maps.fetchMaps'));
    return data;
  };

  const fetchRecentMaps = async (limit = 5) => {
    const { data, error } = await useSupabaseClient()
      .from('maps')
      .select(`
        id,
        title,
        cover_image_url,
        current_slug,
        spots_count,
        user_id,
        profiles:user_id (
          id,
          profile_image_url,
          mappr_handle
        ),
        spots:spots (
          id,
          name,
          description,
          categories:category_id (
            id,
            name
          ),
          cities:cities_id (
            id,
            name,
            country
          )
        )
      `)
      .eq('is_published', true)
      .order('created_at', { ascending: false })
      .limit(limit);
    if (error) showError(t('messages.maps.error-loading'));
    return data;
  };

  const fetchFeaturedMaps = async () => {
    const { data, error } = await useSupabaseClient()
      .from('maps')
      .select(`
        id,
        title,
        cover_image_url,
        current_slug,
        spots_count,
        user_id,
        profiles:user_id (
          id,
          profile_image_url,
          mappr_handle
        ),
        spots:spots (
          id,
          name,
          description,
          categories:category_id (
            id,
            name
          ),
          cities:cities_id (
            id,
            name,
            country
          )
        )
      `)
      .order('updated_at', { ascending: false })
      .eq('is_featured', true)
      .eq('is_published', true);
    if (error) showError(t('messages.maps.error-loading'));
    return data;
  };

  // const fetchMapBySlug = async (id: string) => { }
  // const fetchSpotsByMapId = async (id: string) => { }

  return {
    isLoading,
    noMoreMaps,
    fetchRecentMaps,
    fetchFeaturedMaps,
    fetchMapBySlug,
    fetchMaps,
    fetchDestinations,
    fetchCategories,
    // fetchSpotsByMapId
  };
};
